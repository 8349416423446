export default {
  "row": "dr body-1",
  "cell": "db",
  "counterparty": "dy",
  "counterparty-icon": "dU",
  "avatar": "dj",
  "icon": "dW",
  "counterparty-content": "du",
  "item-counterparty-name": "IS",
  "counterparty-name": "Ic body-1",
  "counterparty-status": "Iq",
  "member": "IZ",
  "method": "IR",
  "operation-date": "IQ",
  "amount": "Ie",
  "remove-transaction": "IB",
  "menu-item": "IE",
  "remove": "Id",
  "tooltip": "II",
  "empty": "It"
};
