export default {
  "cell": "dz caption-bold",
  "empty": "da",
  "transaction": "dH",
  "member": "dO",
  "method": "dm",
  "operation-date": "dp",
  "amount": "dx",
  "label": "dw caption-bold",
  "active": "dN"
};
