/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import Tether from 'tether';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsTableItemQuickActionsComponent extends Component {
  badge = Badge;

  BADGE_TYPE = BADGE_TYPE;

  @service flowLinkManager;
  @service intl;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  get isSoloBasicPlan() {
    return this.subscriptionManager.currentPricePlan?.groupCode === 'solo_basic';
  }

  get isEligibleForFreeTrial() {
    return this.subscriptionManager.currentSubscription.availableTrials.length ? true : false;
  }

  @action
  toggleMenu(showMenu) {
    if (showMenu) {
      this.segment.track('client-list_quick-action-icon_clicked');
      scheduleOnce('afterRender', this, this.positionTether);
    }

    this.args.onToggleMenu?.(showMenu);
  }

  positionTether() {
    if (typeof Tether !== 'undefined' && typeof Tether.position === 'function') {
      Tether.position();
    }
  }

  @action
  onSetupReminders() {
    this.segment.track('client-list_quick-action_clicked', {
      quick_action: 'set_up_reminder',
      cta_type: 'create',
    });
    this.args.onSetReminders();
  }

  @action
  onEditReminders() {
    this.segment.track('client-list_quick-action_clicked', { quick_action: 'edit_reminder' });
    this.args.onSetReminders();
  }

  upsellRemindersTask = dropTask(async () => {
    await this.subscriptionManager.upgradeWithRecommendation('receivable_invoices_reminders');
  });

  @action
  onUpsellReminders() {
    this.segment.track('client-list_quick-action_clicked', {
      quick_action: 'set_up_reminder',
      cta_type: 'upsell',
    });

    this.upsellRemindersTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }
}
