/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

export default class CardSidebarRestrictionsComponent extends Component {
  @service abilities;
  @service segment;
  button = Button;

  get showActiveDaysOption() {
    let activeDaysOptions = this.args.card?.activeDaysOption;
    let canViewRestrictions = this.abilities.can('update settings card');

    return activeDaysOptions || canViewRestrictions;
  }

  get showCategoryTagsOption() {
    if (this.abilities.cannot('view restrictive categories card')) {
      return false;
    }

    let hasCategoryTags = this.args.card?.hasCategoryTags;
    let canViewRestrictions = this.abilities.can('update settings card');

    return hasCategoryTags || canViewRestrictions;
  }

  get canResetSettings() {
    let canUpdateSettings = this.abilities.can('update settings card');
    let canViewAdvancedSettings = this.abilities.can('view restrictions card');
    return !canViewAdvancedSettings && canUpdateSettings;
  }

  get isCategoriesResetIconShown() {
    if (!variation('feature--boolean-acs-ux-improvement')) {
      return false;
    }
    return this.args.card?.hasCategoryTags && this.canResetSettings;
  }

  get isDayResetIconShown() {
    if (!variation('feature--boolean-acs-ux-improvement')) {
      return false;
    }
    return this.args.card?.activeDaysOption && this.canResetSettings;
  }

  @action
  onReset(reset_type) {
    this.segment.track('reset_advanced-card-settings_clicked', {
      reset_type,
    });
    // TODO open modal
  }
}
