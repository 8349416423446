export default {
  "header-cell": "mg",
  "empty": "mD",
  "right": "mV",
  "debitor": "mL",
  "frequency": "mT",
  "date": "mz",
  "status": "ma",
  "amount": "mH",
  "header-content": "mO caption-bold",
  "active": "mm",
  "row--sidebar-open": "mp"
};
