export default {
  "cell": "dv",
  "placeholder-line": "do",
  "counterparty": "dn",
  "counterparty-content": "di",
  "member": "dA",
  "method": "dY",
  "operation": "dh",
  "amount": "df",
  "remove-transaction": "dK",
  "empty": "dG"
};
