export default {
  "envelope": "HW",
  "payment-page": "Hu",
  "header": "OS",
  "title": "Oc",
  "logo": "Oq",
  "detail": "OZ",
  "dummy-field": "OR",
  "input": "OQ",
  "input--select": "Oe",
  "input--readonly": "OB",
  "placeholder": "OE",
  "columns": "Od",
  "mandate": "OI",
  "mandate-title": "Ot",
  "icon-chevron": "OM",
  "mandate-details": "OP",
  "checkbox": "Ol",
  "secure-note": "OX",
  "icon-lock": "OC",
  "cta": "Ok"
};
