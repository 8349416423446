export default {
  "header-cell": "pR",
  "empty": "pQ",
  "right": "pe",
  "debitor": "pB",
  "date": "pE",
  "status": "pd",
  "amount": "pI",
  "header-amount": "pt",
  "header-content": "pM caption-bold",
  "active": "pP"
};
