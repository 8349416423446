/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class CardSettingsValidityPeriodComponent extends Component {
  @service intl;

  changeset = new Changeset(this.args.card);

  @tracked validationState = 'valid';
  @tracked errorMessage = null;

  get period() {
    let today = dayjs().endOf('day');
    let oneMonth = today.add(1, 'month');
    let yesterday = dayjs().subtract(1, 'day').endOf('day');
    let sixMonths = yesterday.add(6, 'month');
    let oneYear = yesterday.add(1, 'year');

    let range = [today, oneMonth, sixMonths, oneYear].map(date => ({
      date: date.toDate(),
      formatted: date.format(DATE_PICKER_FIELD_FORMAT),
    }));

    // Cannot be in map due to ember-intl-analyzer and unused translations rule
    range[0].name = this.intl.t('cards.settings.validity-period.chip1');
    range[1].name = this.intl.t('cards.settings.validity-period.chip2');
    range[2].name = this.intl.t('cards.settings.validity-period.chip3');
    range[3].name = this.intl.t('cards.settings.validity-period.chip4');

    return { min: today, max: oneYear, range };
  }

  validate() {
    let preExpiresAt = this.changeset.preExpiresAt;
    if (!preExpiresAt) {
      this.validationState = 'invalid';
      this.errorMessage = this.intl.t('validations.errors.presence');
      return;
    }

    if (dayjs(preExpiresAt).isAfter(this.period.max, 'day')) {
      this.validationState = 'invalid';
      this.errorMessage = this.intl.t('cards.steps.validity-period.datepicker.error.maximum-date');
      return;
    }

    if (dayjs(preExpiresAt).isBefore(this.period.min, 'day')) {
      this.validationState = 'invalid';
      this.errorMessage = this.intl.t('cards.steps.validity-period.datepicker.error.past-date', {
        today: dateToken({
          date: new Date(),
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.COMPACT,
        }),
      });
      return;
    }

    this.validationState = 'valid';
    this.errorMessage = null;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  submitTask = dropTask(async () => {
    this.changeset.execute();
    await this.args.onSubmit();
  });

  @action
  setProperty(prop, val) {
    let newDate = val && dayjs(val).endOf('day').toDate();
    this.changeset.set(prop, newDate);
    this.validate();
    this.persistState();
  }
}
