/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsInvoicesSidebarComponent extends Component {
  @service intl;
  @service abilities;

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item2'),
    ];
  }
}
