export default {
  "card": "te",
  "row": "tB",
  "icon": "tE",
  "unrevokable": "td",
  "active": "tI",
  "kyc-pending": "tt",
  "shareholder-info": "tM",
  "name": "tP",
  "iban": "tl",
  "tooltip": "tX",
  "shareholder-capital": "tC",
  "shareholder-capital-amount": "tk",
  "deposit-capital": "tJ",
  "valid": "ts",
  "pending": "tF",
  "promised-capital-valid": "tg",
  "promised-capital": "tD"
};
