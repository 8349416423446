export default {
  "header-cell": "gJ",
  "empty": "gs",
  "emitter": "gF",
  "date": "gg",
  "amount": "gD",
  "status": "gV",
  "header-content": "gL caption-bold",
  "active": "gT"
};
