export default {
  "row": "kS",
  "active": "kc",
  "status": "kq",
  "align-right": "kZ",
  "quick-actions": "kR",
  "cell-approve": "kQ",
  "account-select": "ke",
  "hidden": "kB",
  "no-padding": "kE",
  "animated": "kd",
  "fadein-item": "kI",
  "cell": "kt body-2",
  "cell-content": "kM",
  "amount": "kP body-1",
  "subtitle": "kl caption"
};
