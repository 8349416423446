export default {
  "aside": "RH",
  "aside-section": "RO",
  "aside-title": "Rm caption-bold",
  "placeholder": "Rp",
  "footer": "Rx",
  "cancel-button": "Rw",
  "attachment-date": "RN body-2",
  "attachment-item": "Rv"
};
