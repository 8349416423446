export default {
  "edit": "Pb",
  "options": "Py",
  "option": "PU body-2 mb-16",
  "option-line": "Pj",
  "category-tags": "PW",
  "option-icons": "Pu",
  "option-icon": "lS",
  "valid": "lc"
};
