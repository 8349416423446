export default {
  "sidebar-header": "mZ",
  "sidebar-header__top": "mR",
  "sidebar-header__close-button": "mQ",
  "close-button": "me",
  "sidebar-header__body": "mB",
  "sidebar-header__content": "mE",
  "sidebar-header__date": "md",
  "infobox": "mI",
  "infobox--link": "mt",
  "ghost": "mM",
  "fail": "mP",
  "success": "ml"
};
