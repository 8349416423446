export default {
  "status-avatar": "gz mr-16",
  "row": "ga",
  "active": "gH",
  "animated": "gO",
  "fadein-item": "gm",
  "cell": "gp",
  "cell-content": "gx",
  "disabled": "gw",
  "not-disabled": "gN",
  "cell-amount": "gv",
  "ghost": "go",
  "validated": "gn",
  "status-pin": "gi",
  "item-emitter-name": "gA",
  "item-emitter-name-text": "gY"
};
