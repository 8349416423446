/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, Spinner } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { InvoiceDetailsCard } from 'qonto/react/components/attachments/card/invoice-details-card';
import { InvoiceDetailsCardLoading } from 'qonto/react/components/attachments/loading/invoice-details-card-loading';
export default class AttachmentsAttachmentsSuggestedSidebarComponent extends Component {
  spinner = Spinner;
  invoiceDetailsCard = InvoiceDetailsCard;
  invoiceDetailsCardLoading = InvoiceDetailsCardLoading;
  lottiePlayer = LottiePlayer;

  @tracked scrolledTop = true;

  get displayPagination() {
    return this.args.totalCount && this.args.totalCount > 25 && !this.args.isSearchError;
  }

  get showEmptyResultsState() {
    let hasInvoices =
      Boolean(this.args.supplierInvoices?.length) || Boolean(this.args.receivableInvoices?.length);
    let hasFilters = Boolean(this.args.searchQuery) || Boolean(this.args.selectedPeriod);

    return !hasInvoices && hasFilters;
  }

  get maxDate() {
    return dayjs().toDate();
  }

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
