export default {
  "content": "eG",
  "balance": "er",
  "balance-failed": "eb",
  "bank-infos": "ey",
  "bank-name": "eU",
  "bank-identifier": "ej",
  "bank-iban": "eW",
  "footer-container": "eu",
  "links": "BS",
  "links--start-verification": "Bc",
  "link": "Bq",
  "start-verification-link": "BZ",
  "last-sync": "BR"
};
