export default {
  "sub-menu": "Nh",
  "collapsed": "Nf",
  "collapse-left": "NK",
  "responsive": "NG",
  "expanded": "Nr",
  "no-slide": "Nb",
  "visible": "Ny",
  "hidden": "NU",
  "group": "Nj",
  "promo-box": "NW"
};
