export default {
  "wrapper": "ae",
  "label": "aB",
  "input": "aE",
  "icon-trash": "ad",
  "hidden": "aI",
  "icon-color": "at",
  "icon-edit": "aM",
  "icon-edit-save": "aP",
  "button-container": "al"
};
