export default {
  "envelope": "ai",
  "email": "aA",
  "logo": "aY",
  "subject": "ah",
  "message": "af",
  "payment-details": "aK",
  "contact": "aG",
  "detail": "ar",
  "cta": "ab"
};
