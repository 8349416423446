export default {
  "card": "ta",
  "avatar": "tH",
  "content": "tO",
  "content-top": "tm",
  "fullname": "tp",
  "representative": "tx",
  "icon": "tw",
  "type": "tN",
  "menu-container": "tv",
  "dropdown-menu": "to",
  "menu-item": "tn body-2",
  "delete": "ti"
};
