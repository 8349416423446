export default {
  "wrapper": "Ow",
  "header": "ON",
  "status": "Ov",
  "close-button": "Oo",
  "slug": "On",
  "category": "Oi",
  "category-title": "OA",
  "activity-tag-select": "OY",
  "footer": "Oh"
};
