/* import __COLOCATED_TEMPLATE__ from './attachments-suggested.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ErrorInfo } from 'qonto/utils/error-info';

const INVOICE_ALREADY_MATCHED_ERROR_CODE = 'transaction_attachment_exists';

export default class AttachmentsSuggestedComponent extends Component {
  @service intl;
  @service store;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service sentry;

  @tracked suggestedAttachments = [];
  @tracked selectedSuggestedAttachmentIndex = 0;

  @tracked selectedReceivableInvoiceId;
  @tracked selectedSupplierInvoiceId;

  @tracked invoicesCurrentPage = 1;
  @tracked invoicesTotalCount;
  @tracked invoicesTotalPages;
  @tracked invoicesPerPage;

  @tracked selectedPeriod;

  @tracked invoicesSearchQuery = '';
  @tracked dateFrom;
  @tracked dateTo;

  constructor() {
    super(...arguments);
    /* eslint-disable ember-concurrency/no-perform-without-catch */
    this.hasSuggestedAttachments = this.args.transaction.attachmentSuggestionIds.length;
    this.hasSupplierInvoices = this.args.hasSupplierInvoices;
    this.hasReceivableInvoices = this.args.hasReceivableInvoices;
    if (variation('feature--boolean-improve-manual-matching')) {
      this.fetchInvoicesTask.perform();
    } else {
      this.fetchSuggestedAttachmentsTask.perform();
    }
    /* eslint-enable ember-concurrency/no-perform-without-catch */
  }

  get selectedSuggestedAttachment() {
    return this.suggestedAttachments[this.selectedSuggestedAttachmentIndex];
  }

  get isDebitTransaction() {
    return this.args.transaction.side === 'debit';
  }

  get supplierInvoices() {
    return this.fetchSupplierInvoicesTask.lastSuccessful?.value || [];
  }

  get receivableInvoices() {
    return this.fetchReceivableInvoicesTask.lastSuccessful?.value || [];
  }

  get file() {
    if (variation('feature--boolean-improve-manual-matching')) {
      if (!this.selectedSupplierInvoiceId && !this.selectedReceivableInvoiceId) {
        return null;
      }

      if (this.isDebitTransaction) {
        return this.supplierInvoices
          .find(invoice => invoice.id === this.selectedSupplierInvoiceId)
          ?.get('attachment.file');
      } else {
        return this.receivableInvoices
          .find(invoice => invoice.id === this.selectedReceivableInvoiceId)
          ?.attachment?.get('file');
      }
    } else {
      return this.selectedSuggestedAttachment?.file;
    }
  }

  get isSearchError() {
    return Boolean(
      (this.invoicesSearchQuery || this.selectedPeriod) &&
        (this.fetchSupplierInvoicesTask.last?.isError ||
          this.fetchReceivableInvoicesTask.last?.isError)
    );
  }

  get isError() {
    if (variation('feature--boolean-improve-manual-matching')) {
      return Boolean(
        !this.invoicesSearchQuery &&
          !this.selectedPeriod &&
          (this.fetchSupplierInvoicesTask.last?.isError ||
            this.fetchReceivableInvoicesTask.last?.isError)
      );
    } else {
      return this.fetchSuggestedAttachmentsTask.lastComplete.isError;
    }
  }

  get hasNoInvoiceSelected() {
    return (
      this.hasInvoices &&
      ((this.isDebitTransaction && !this.selectedSupplierInvoiceId) ||
        (!this.isDebitTransaction && !this.selectedReceivableInvoiceId))
    );
  }

  get isLoading() {
    return this.fetchSuggestedAttachmentsTask.isRunning || this.fetchInvoicesTask.isRunning;
  }

  get hasInvoices() {
    if (this.isDebitTransaction) {
      return this.hasSupplierInvoices;
    } else {
      return this.hasReceivableInvoices;
    }
  }

  @action
  selectSuggestedAttachment(attachmentIndex) {
    this.selectedSuggestedAttachmentIndex = attachmentIndex;
  }

  fetchSuggestedAttachmentsTask = dropTask(
    waitFor(async () => {
      try {
        let attachments = await this.store.query('attachment', {
          organization_id: this.organizationManager.organization.id,
          filters: { ids: this.args.transaction.attachmentSuggestionIds },
          per_page: 500,
        });
        // attachmentSuggestionIds is sorted by matching score, and we should keep this order
        // for displaying suggestedAttachments (note: matching score is not part of attachment object)
        this.suggestedAttachments = this.args.transaction.attachmentSuggestionIds.map(id =>
          attachments.find(att => att.id === id)
        );
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);

        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    })
  );

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);

    this.invoicesSearchQuery = query;

    this.invoicesCurrentPage = 1;
    this.selectedSupplierInvoiceId = null;
    this.selectedReceivableInvoiceId = null;

    await this.fetchInvoicesTask.perform();
  });

  updatePeriodSelectionTask = dropTask(
    waitFor(async period => {
      this.selectedPeriod = period;
      this.dateFrom = period?.startDate || null;
      this.dateTo = period?.endDate || null;

      await this.fetchInvoicesTask.perform();
    })
  );

  cancelPeriodSelectionTask = dropTask(
    waitFor(async () => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(null);
    })
  );

  handlePeriodUpdateTask = dropTask(
    waitFor(async value => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(value);
    })
  );

  fetchSupplierInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (
        this.hasSuggestedAttachments &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            attachment_id: this.args.transaction.attachmentSuggestionIds,
          },
        });

        supplierInvoices = response.toArray();
        this.selectedSupplierInvoiceId = supplierInvoices[0]?.id;
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'created_at:desc',
        };

        if (this.invoicesSearchQuery !== '') {
          params.query = this.invoicesSearchQuery;
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.dateFrom && this.dateTo) {
          params.filter = {
            created_at_from: dayjs(this.dateFrom).startOf('day').toISOString(),
            created_at_to: dayjs(this.dateTo).endOf('day').toISOString(),
          };
        }

        let response = await this.store.query('supplier-invoice', params);

        supplierInvoices = response.toArray();

        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchReceivableInvoicesTask = dropTask(
    waitFor(async () => {
      let receivableInvoices;

      if (
        this.hasSuggestedAttachments &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('receivable-invoice', {
          filter: {
            attachment_id: this.args.transaction.attachmentSuggestionIds.join(),
          },
        });

        receivableInvoices = response.toArray();
        this.selectedReceivableInvoiceId = receivableInvoices[0]?.id;
        this.updatePagination(response.meta);
      } else {
        if (this.invoicesSearchQuery || (this.dateFrom && this.dateTo)) {
          let searchParams = {
            query: this.invoicesSearchQuery,
            query_fields: 'customer_name,amount,file_name,invoice_number',
            sorts: [{ field: 'due_date', direction: 'asc' }],
            pagination: {
              page: this.invoicesCurrentPage,
              per_page: 25,
            },
          };

          if (this.dateFrom && this.dateTo) {
            searchParams.filters = [
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateFrom).startOf('day').toISOString()],
                operator: 'gte',
              },
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateTo).endOf('day').toISOString()],
                operator: 'lte',
              },
            ];
          }

          let response = await this.store.adapterFor('receivable-invoice').search(searchParams);
          receivableInvoices = response.invoices;
          this.updatePagination(response.meta);
        } else {
          let response = await this.store.query('receivable-invoice', {
            page: { number: this.invoicesCurrentPage, size: 25 },
            sort: 'due_date,-number',
          });

          receivableInvoices = response.toArray();
          this.updatePagination(response.meta);
        }
      }
      return receivableInvoices;
    })
  );

  fetchInvoicesTask = dropTask(async () => {
    try {
      if (this.isDebitTransaction) {
        await this.fetchSupplierInvoicesTask.perform();
      } else {
        await this.fetchReceivableInvoicesTask.perform();
      }
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  handleChangeInvoicesPageTask = dropTask(
    waitFor(async pageNumber => {
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      this.invoicesCurrentPage = pageNumber;

      await this.fetchInvoicesTask.perform();
    })
  );

  linkSuggestedAttachmentTask = dropTask(
    waitFor(async () => {
      let isManualMatchingEnabled = variation('feature--boolean-improve-manual-matching');

      if (isManualMatchingEnabled) {
        this.segment.track('find_on_qonto_button_match_clicked');
      }

      try {
        let attachment;

        if (isManualMatchingEnabled) {
          if (this.isDebitTransaction) {
            let selectedSupplierInvoice = await this.store.findRecord(
              'supplier-invoice',
              this.selectedSupplierInvoiceId
            );

            attachment = await selectedSupplierInvoice.attachment;
          } else {
            let selectedReceivableInvoice = await this.store.findRecord(
              'receivable-invoice',
              this.selectedReceivableInvoiceId
            );

            attachment = await selectedReceivableInvoice.attachment;
          }
        } else {
          attachment = this.selectedSuggestedAttachment;
        }

        if (!this.args.transaction.attachments.includes(attachment)) {
          this.args.transaction.attachments.push(attachment);
        }

        await this.args.transaction.linkAttachment([attachment]);

        if (!isManualMatchingEnabled) {
          this.segment.track('attachments_suggestion_modal_validation_button_clicked', {
            membership_role: this.organizationManager.membership.role,
            suggestion_order: this.selectedSuggestedAttachmentIndex,
          });
        }

        this.args.onClose();

        if (isManualMatchingEnabled) {
          this.toastFlashMessages.toastInfo(this.intl.t('find-invoice-modal.match.success.toast'));
        }
      } catch (error) {
        let isInvoiceAlreadyMatchedError =
          error.status === 422 &&
          error.errors?.some(({ detail }) => detail?.code === INVOICE_ALREADY_MATCHED_ERROR_CODE);

        if (isInvoiceAlreadyMatchedError) {
          this.segment.track('find_on_qonto_button_attachments-link-re-attempted');

          this.toastFlashMessages.toastError(
            this.intl.t('find-invoice-modal.match.error-already-matched.toast')
          );
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    })
  );

  updatePagination(meta) {
    this.invoicesTotalCount = meta.total_count || meta.total;
    this.invoicesCurrentPage = meta.current_page;
    this.invoicesTotalPages = meta.total_pages;
    this.invoicesPerPage = meta.per_page;
  }
}
