export default {
  "row": "pD body-1",
  "active": "pV",
  "cell": "pL body-2",
  "empty": "pT",
  "no-padding": "pz",
  "cell-content": "pa",
  "item-disabled": "pH",
  "item-amount": "pO body-1",
  "centered-cell": "pm",
  "debitor": "pp",
  "item-counterparty-icon": "px mr-16",
  "avatar": "pw",
  "item-counterparty-name": "pN",
  "item-counterparty-reference": "pv",
  "item-counterparty-name-status": "po",
  "item-counterparty-name-text": "pn",
  "amount-on-hold": "pi",
  "icon-on-hold": "pA"
};
