export default {
  "tooltip": "wG",
  "wrapper": "wr",
  "collapsed": "wb",
  "label": "wy body-1",
  "selected": "wU",
  "icon-wrapper": "wj",
  "icon": "wW",
  "locked-icon": "wu",
  "notification-count": "NS",
  "fade-in": "Nc",
  "responsive": "Nq"
};
