export default {
  "tile-layout": "em",
  "focus": "ep",
  "no-shadow": "ex",
  "tile-layout--start-verification": "ew",
  "header": "eN",
  "headings": "ev",
  "management": "eo",
  "logo": "en",
  "title": "ei title-4",
  "name": "eA title-4",
  "loading": "eY",
  "menu-item": "eh body-2",
  "close-account": "ef",
  "content": "eK"
};
