export default {
  "row": "Hm body-1",
  "cell": "Hp",
  "empty": "Hx",
  "amount": "Hw",
  "review": "HN",
  "cell-content": "Hv",
  "icon": "Ho",
  "name": "Hn",
  "name-text": "Hi",
  "quick-actions": "HA",
  "account-select": "HY"
};
