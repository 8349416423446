/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { RemindersLoading } from 'qonto/react/components/clients/sidebar/reminders-loading';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsSidebarComponent extends Component {
  badge = Badge;
  RemindersLoading = RemindersLoading;

  BADGE_TYPE = BADGE_TYPE;

  @service flowLinkManager;
  @service intl;
  @service segment;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  get reminders() {
    return this.args.clientRemindersConfigurationRules || [];
  }

  get hasReminders() {
    return this.reminders.length > 0;
  }

  get isSoloBasicPlan() {
    return this.subscriptionManager.currentPricePlan?.groupCode === 'solo_basic';
  }

  get isEligibleForFreeTrial() {
    return this.subscriptionManager.currentSubscription.availableTrials.length ? true : false;
  }

  get subtitle() {
    if (this.isSoloBasicPlan) {
      if (this.isEligibleForFreeTrial) {
        return this.intl.t('client.sidebar.payment-reminders.empty-state.subtitle.free-trial');
      } else {
        return this.intl.t('client.sidebar.payment-reminders.empty-state.subtitle.upgrade-old');
      }
    } else {
      return this.intl.t('client.sidebar.payment-reminders.subtitle.empty-state');
    }
  }

  get upsellButtonText() {
    if (this.isEligibleForFreeTrial) {
      return this.intl.t('client.sidebar.payment-reminders.free-trial.empty-state.cta');
    } else {
      return this.intl.t('client.sidebar.payment-reminders.upgrade.empty-state.cta');
    }
  }

  @action incrementIndex(index) {
    return index + 1;
  }

  @action closeSidebar(clientId) {
    this.args.closeSidebar();
    document.querySelector(`[data-item-id="${clientId}"]`)?.focus();
  }

  @action comparePricePlans() {
    if (this.isEligibleForFreeTrial) {
      this.segment.track('side-panel_free-trial_clicked');
    } else {
      this.segment.track('side-panel_upsell_clicked');
    }

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: {
        lineup: 'solo',
        recurrence: 'monthly',
      },
    });
  }

  upsellRemindersTask = dropTask(async () => {
    await this.subscriptionManager.upgradeWithRecommendation('receivable_invoices_reminders');
  });

  @action
  onUpsellReminders() {
    this.segment.track('side-panel_upsell_clicked', {
      origin: 'client-list',
    });

    this.upsellRemindersTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }
}
