export default {
  "main": "Rs",
  "spinner": "RF",
  "error": "Rg",
  "error-illustration": "RD",
  "wrapper": "RV",
  "content": "RL",
  "illustration": "RT mb-32",
  "title": "Rz mb-16 title-2",
  "subtitle": "Ra body-2"
};
