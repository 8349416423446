export default {
  "aside": "ZY",
  "aside-section": "Zh",
  "aside-content": "Zf",
  "aside-footer": "ZK",
  "aside-title": "ZG caption-bold",
  "accountant-upsell": "Zr",
  "file-status": "Zb",
  "file-status-icon": "Zy",
  "file-status-text": "ZU",
  "file-status-label": "Zj body-1",
  "file-status-description": "ZW body-2"
};
