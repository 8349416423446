export default {
  "item": "NI",
  "qonto-pilot-nav-item": "Nt",
  "wrapper": "NM",
  "collapsed": "NP",
  "label": "Nl body-1",
  "icon-wrapper": "NX",
  "icon": "NC",
  "key": "Nk",
  "keys": "NJ"
};
