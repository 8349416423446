export default {
  "wrapper": "pG",
  "modal": "pr",
  "close-button": "pb",
  "content": "py",
  "title": "pU title-1",
  "description": "pj body-2",
  "slides-container": "pW",
  "slide": "pu"
};
