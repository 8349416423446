export default {
  "row": "Tq body-1",
  "active": "TZ",
  "cell": "TR",
  "empty": "TQ",
  "connection": "Te",
  "connection-avatar": "TB",
  "connection-name": "TE",
  "member-name": "Td body-2",
  "member-role": "TI caption",
  "status": "Tt",
  "failure": "TM",
  "error": "TP",
  "hidden": "Tl"
};
