export default {
  "wrapper": "eq",
  "core-information": "eZ",
  "iban": "eR",
  "bic": "eQ",
  "full-line": "ee",
  "secondary-information": "eB",
  "title": "eE",
  "rib": "ed",
  "rib-copy": "eI",
  "flex-column": "et",
  "label": "eM body-1",
  "value": "eP body-2",
  "right-spaced": "el",
  "name": "eX title-3",
  "disclaimer": "eC"
};
