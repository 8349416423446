export default {
  "wrapper": "xP",
  "badge-trial": "xl",
  "header": "xX",
  "list": "xC body-2",
  "main": "xk",
  "card": "xJ",
  "container": "xs",
  "header-button": "xF",
  "error-text": "xg",
  "mt-16": "xD"
};
