export default {
  "sidebar": "gr",
  "sidebar-header": "gb",
  "close-button": "gy",
  "setup-mode-content": "gU",
  "edit-mode-content": "gj",
  "reminders-header": "gW",
  "reminders-title-container": "gu",
  "reminders-title": "DS",
  "reminders-subtitle": "Dc",
  "reminders-list": "Dq",
  "reminder": "DZ",
  "reminder-due-date": "DR",
  "upsell-button-container": "DQ"
};
