export default {
  "budget-creation-form": "Er",
  "period-label": "Eb",
  "periods": "Ey",
  "yearly": "EU",
  "yearly-toggle": "Ej",
  "toggle-tooltip": "EW",
  "tooltip": "Eu",
  "year": "dS",
  "total-budget": "dc",
  "total-budget-value": "dq",
  "divider": "dZ"
};
