export default {
  "wrapper": "Ns",
  "active": "NF",
  "expanded": "Ng",
  "fade-in": "ND",
  "hidden": "NV",
  "visible": "NL",
  "item-list": "NT",
  "item-list-slide-down": "Nz",
  "dropdown-trigger": "Na overlay",
  "settings-dropdown-slide-down": "NH",
  "responsive": "NO",
  "is-selected": "Nm",
  "is-active": "Np",
  "dropdown": "Nx"
};
