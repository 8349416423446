export default {
  "header-cell": "HX",
  "type": "HC",
  "request-date": "Hk",
  "amount": "HJ",
  "quick-actions": "Hs",
  "empty": "HF",
  "header-content": "Hg caption-bold",
  "active": "HD"
};
