export default {
  "row": "mx",
  "icon": "mw",
  "cell": "mN",
  "empty": "mv",
  "no-padding": "mo",
  "cell-content": "mn",
  "right": "mi",
  "cube": "mA",
  "row--sidebar-open": "mY",
  "status": "mh"
};
