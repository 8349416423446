export default {
  "dual-nav": "wR",
  "header": "wQ",
  "expanded": "we",
  "collapsed": "wB",
  "responsive": "wE",
  "overlay": "wd",
  "visible": "wI",
  "footer": "wt"
};
