/* import __COLOCATED_TEMPLATE__ from './info.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

const FIELDS = ['firstName', 'lastName', 'email', 'capitalAmount'];

export default class CapitalDepositStakeholderInfoComponent extends Component {
  @service segment;

  @reads('args.context.stakeholder.didValidate') isValidationEnabled;
  @tracked isAmountInputErrorVisible = false;

  nextStepTask = dropTask(async stakeholder => {
    let s = await stakeholder.validate({ on: FIELDS });
    this.isAmountInputErrorVisible = true;

    if (s.validations.isValid) {
      this.isAmountInputErrorVisible = false;
      stakeholder.set('didValidate', false);
      this.segment.track('kdeposit_shareholder_capital_shares_filled');
      this.args.transitionToNext();
    } else {
      stakeholder.set('didValidate', true);
    }
  });

  @action
  updateCapitalAmount(value) {
    this.isAmountInputErrorVisible = false;
    this.args.context.stakeholder.capitalAmount = parseFloat(value);
  }
}
