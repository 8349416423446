/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ApprovalWorkflowRulesetAttrVisualizerSuccess extends Component {
  @service intl;

  get successCopy() {
    let { requestType } = this.args;

    switch (requestType) {
      case 'transfer':
        return this.intl.t('approval-workflows.ruleset.steps.approval.transfer-requests');
      case 'reimbursement':
      case 'expense_report':
      case 'mileage':
      default:
        return this.intl.t('approval-workflows.ruleset.steps.approval.reimbursement-requests');
    }
  }
}
