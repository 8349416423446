export default {
  "panel-container": "If",
  "selector-container": "IK",
  "container-title": "IG",
  "panel-title": "Ir",
  "panel-title-text": "Ib",
  "panel-icon": "Iy",
  "icon": "IU",
  "animate-in": "Ij",
  "panel-content": "IW",
  "error-message": "Iu"
};
