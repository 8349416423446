export default {
  "not-active": "Ea",
  "title": "EH",
  "details": "EO",
  "detail": "Em",
  "text-secondary": "Ep",
  "text-primary-a": "Ex",
  "amounts": "Ew",
  "contents-container": "EN",
  "contents": "Ev",
  "menu-item": "Eo",
  "archive": "En",
  "amount-settled": "Ei",
  "shadow": "EA",
  "name": "EY title-4",
  "tooltip": "Eh"
};
