export default {
  "header": "lv",
  "hidden": "lo",
  "header-inline": "ln",
  "header-inline__search-bar": "li",
  "header-bottom": "lA",
  "margin-top": "lY",
  "header-bottom-loading": "lh",
  "header-bottom-loading-state": "lf",
  "actions__filters": "lK",
  "actions__filters__toggle-btn": "lG",
  "tabs": "lr",
  "header-inline__filters": "lb",
  "filter-ml": "ly",
  "filter-clear": "lU"
};
