/* import __COLOCATED_TEMPLATE__ from './edit-notification-rule.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask, task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class QontoHubModalsEditNotificationRule extends Component {
  @service intl;
  @service toastFlashMessages;

  get amountInEUR() {
    return this.args.data.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    let amount = this.args.data.hubNotificationRule.value;

    if (!amount || amount < 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  estimateAlertCountPerDayTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);

    try {
      let { estimate } = await this.args.data.hubNotificationRule.estimate();

      return estimate.average;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  confirmTask = task(async e => {
    e.preventDefault();
    try {
      await this.args.data.hubNotificationRule.save();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('qonto-hub.connect.details.settings.edit-rule-success')
      );
      this.args.close('confirm');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  changeValueTask = task(async value => {
    await this.estimateAlertCountPerDayTask.cancelAll({ resetState: true });

    let amount = parseInt(value, 10);

    this.args.data.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);

    if (amount > 0 && this.args.data.hubNotificationRule.trigger === 'transaction') {
      await this.estimateAlertCountPerDayTask.perform();
    }
  });

  @action
  cancel() {
    this.args.data.hubNotificationRule.rollbackAttributes();
    this.args.close('cancel');
  }
}
