export default {
  "sidebar": "Tu",
  "scroll": "zS",
  "header": "zc",
  "close": "zq",
  "vat": "zZ",
  "labels": "zR",
  "actions": "zQ",
  "buttons-container": "ze"
};
