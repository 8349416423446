export default {
  "row": "DD",
  "active": "DV",
  "cell": "DL",
  "name-cell": "DT",
  "email-cell": "Dz",
  "actions-cell": "Da",
  "name-container": "DH",
  "quick-actions-wrapper": "DO",
  "empty": "Dm",
  "name-icon-container": "Dp",
  "icon": "Dx",
  "tooltip-container": "Dw",
  "missing-details-container": "DN",
  "missing-details-label": "Dv body-2",
  "warning-icon": "Do Dx"
};
