export default {
  "details": "zB",
  "top-details": "zE",
  "name": "zd",
  "account-infobox": "zI",
  "row": "zt",
  "icon": "zM",
  "account-number": "zP",
  "currency": "zl",
  "activity-tag": "zX",
  "activity-icon": "zC",
  "edit-btn": "zk",
  "action-buttons-container": "zJ"
};
