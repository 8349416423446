export default {
  "connection-sidebar-header": "Lv",
  "title": "Lo",
  "close": "Ln",
  "api-client": "Li",
  "api-client-avatar": "LA",
  "api-client-name": "LY",
  "disclaimer": "Lh",
  "expired": "Lf"
};
