import { template as template_532ed404fd3a469398c4a19521625ce2 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Tooltip from '@qonto/ui-kit/components/tooltip';
import styles from './selector-option.strict-module.css';
export default class ApprovalWorkflowFormStepSelectorsApproversSelectorSelectorOption extends Component {
    @service
    intl;
    get shouldHideTooltip() {
        return !this.args.option?.disabled || this.args.option?.selected;
    }
    get message() {
        return this.intl.t('approval-workflows.form.tooltip-approver');
    }
    get isOptionDisabled() {
        return this.args.option?.disabled;
    }
    static{
        template_532ed404fd3a469398c4a19521625ce2(`
    <Tooltip
      @message={{this.message}}
      @position='top right'
      @disabled={{this.shouldHideTooltip}}
      class={{styles.tooltip}}
      data-test-tooltip-trigger-id={{@option.key}}
      ...attributes
    >
      <div
        class='{{styles.option-item}} {{if this.isOptionDisabled styles.disabledColor}}'
        data-test-selector-option={{@option.key}}
      >
        <span class={{styles.option-item-text}} data-test-select-approver-name={{@option.key}}>
          {{@option.value}}
        </span>
        <span
          class='{{styles.option-item__subtitle}} {{if this.isOptionDisabled styles.disabledColor}}'
          data-test-select-approver-description={{@option.key}}
        >
          {{@option.description}}
        </span>
      </div>
    </Tooltip>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
