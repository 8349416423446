export default {
  "container": "Mz",
  "label": "Ma",
  "amount-input": "MH",
  "error": "MO",
  "error-message": "Mm",
  "title": "Mp",
  "subtitle": "Mx",
  "submit-button": "Mw btn btn--primary btn--large"
};
