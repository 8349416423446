/* import __COLOCATED_TEMPLATE__ from './attachment-viewer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import window from 'ember-window-mock';

const DEFAULT_MAX_SIZE = 15 * 1e6;
const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png,gif';
const DEFAULT_FILES_LIMIT = 5;

export default class AttachmentViewer extends Component {
  @service modals;
  @service segment;
  @service organizationManager;

  @tracked _selectedAttachmentIndex = 0;
  @tracked defaultMaxSize = DEFAULT_MAX_SIZE;
  @tracked defaultExtensions = DEFAULT_EXTENSIONS;
  @tracked defaultFilesLimit = DEFAULT_FILES_LIMIT;
  @tracked showDropzone = false;

  @tracked _attachments = [];

  constructor() {
    super(...arguments);
    this.setAttachment();
  }

  async setAttachment() {
    let { transfer, transaction } = this.args;

    if (transfer) {
      this._attachments = await transfer.attachments;
    } else if (transaction) {
      this._attachments = await transaction.attachments;
    }
  }

  get attachments() {
    return this.args.attachments || this._attachments;
  }

  get model() {
    return this.args.transaction || this.args.transfer;
  }

  get title() {
    return this.args.title || this.selectedAttachmentName;
  }

  get hideSidebar() {
    return this.args.transfer && !this.displayProbativeFileStatus;
  }

  get deletable() {
    let { deletable, transaction } = this.args;
    if (!this.hasAttachments || !transaction) return false;

    let { isFee, isPagoPaPayment, hasUndeletableAttachment } = transaction || {};

    return deletable ?? ![isFee, isPagoPaPayment, hasUndeletableAttachment].some(Boolean);
  }

  get canHaveAttachments() {
    let { attachmentRequired, attachmentLost } = this.args.transaction || {};

    return attachmentRequired && !attachmentLost;
  }

  get shouldDisplayAttachmentPreview() {
    if (
      this.args.transaction &&
      this.args.transaction.isBilling &&
      this.args.transaction.attachmentsFiles.length === 0
    ) {
      return false;
    }

    return this.canHaveAttachments || this.args.transfer;
  }

  get shouldDisplayAttachmentDetails() {
    return this.hasAttachments && this.args.transaction;
  }

  get selectedAttachmentIndex() {
    let selectedFile = this.args.selectedFile || this.attachments[0]?.file;

    let index = this.attachments.findIndex(({ id }) => selectedFile?.fileUrl?.includes(id));

    return index >= 0 ? this._selectedAttachmentIndex + index : 0;
  }

  get selectedAttachment() {
    return this.attachments[this.selectedAttachmentIndex] || {};
  }

  get isFirstAttachmentSelected() {
    return this.selectedAttachmentIndex === 0;
  }

  get isLastAttachmentSelected() {
    return this.selectedAttachmentIndex === this.attachments.length - 1;
  }

  get hasAttachments() {
    return this.attachments.length > 0;
  }

  get hasMultipleAttachments() {
    return this.attachments.length > 1;
  }

  get showPrevButton() {
    return this.hasMultipleAttachments && !this.isFirstAttachmentSelected;
  }

  get showNextButton() {
    return this.hasMultipleAttachments && !this.isLastAttachmentSelected;
  }

  get displayProbativeFileStatus() {
    return ['available', 'corrupted'].includes(this.selectedAttachment.probativeAttachment?.status);
  }

  get selectedAttachmentName() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileName : file?.fileName;
  }

  get selectedAttachmentType() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileContentType : file?.fileContentType;
  }

  get selectedAttachmentUrl() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileUrl : file?.fileUrl;
  }

  get selectedAttachmentDownloadUrl() {
    let { downloadUrl, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment.downloadUrl : downloadUrl;
  }

  removeAttachmentTask = task(async () => {
    await this.args.remove(this.model, this.selectedAttachment.file);
    this.segment.track('transfer_detail_delete_confirm');

    this._selectedAttachmentIndex = 0;
  });

  @action
  handleRemove() {
    this.segment.track('transfer_side_panel_view_attach_delete');
    this.modals.open('attachments/confirm-delete-modal', {
      file: this.selectedAttachment.file,
      model: this.model,
      confirmTask: this.removeAttachmentTask,
    });
  }

  @action
  handleDownload() {
    window.open(this.selectedAttachmentDownloadUrl);
  }

  @action
  handleNext() {
    if (this.isLastAttachmentSelected) return;
    this._selectedAttachmentIndex++;
  }

  @action
  handlePrev() {
    if (this.isFirstAttachmentSelected) return;
    this._selectedAttachmentIndex--;
  }

  @action
  toggleShowDropzone(showDropzone) {
    this.showDropzone = showDropzone;
  }
}
