export default {
  "container": "XV",
  "content": "XL",
  "table": "XT",
  "table-body": "Xz",
  "title": "Xa",
  "title-with-badge": "XH",
  "metal-card-title": "XO",
  "subtitle": "Xm",
  "card-image": "Xp",
  "button-container": "Xx"
};
