export default {
  "header": "aW",
  "header__main": "au",
  "note": "HS",
  "review-status": "Hc",
  "reviewer-wrapper": "Hq",
  "reviewer": "HZ",
  "decline-note": "HR",
  "decline-note-title": "HQ",
  "status-dot": "He",
  "approved": "HB",
  "rejected": "HE"
};
