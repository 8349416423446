export default {
  "hero-gradient": "CZ",
  "hero-wrapper": "CR",
  "container-text": "CQ",
  "title-with-badge": "Ce",
  "recycled-badge": "CB",
  "hero-label": "CE",
  "hero-card": "Cd",
  "svg-gradient": "CI",
  "design": "Ct",
  "design-image-container": "CM",
  "design-image-overlay": "CP",
  "design-item": "Cl",
  "hero-title": "CX",
  "hero-subtitle": "CC",
  "metal-illustration": "Ck",
  "cards": "CJ",
  "metal-illustration-old": "Cs",
  "rotating": "CF",
  "verticaling": "Cg",
  "card": "CD",
  "circle": "CV"
};
