export default {
  "wrapper": "wF",
  "header": "wg",
  "no-separator": "wD",
  "tabs": "wV",
  "tab": "wL body-1 overlay",
  "selected": "wT",
  "organization-list": "wz",
  "content": "wa",
  "empty-state": "wH",
  "illustration": "wO",
  "body": "wm body-1",
  "cta": "wp btn btn--tertiary"
};
