export default {
  "card": "dQ",
  "header": "de",
  "budget-caption": "dB caption",
  "amount-spent-section": "dE",
  "amount-spent-icon-container": "dd",
  "amount-spent-title": "dI title-3",
  "amount-budgeted-section": "dt",
  "amount-budgeted-title": "dM title-3"
};
