/* import __COLOCATED_TEMPLATE__ from './conditions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { APPROVAL_WORKFLOW_CONDITION_FIELDS } from 'qonto/constants/approval-workflow';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component {
  @service intl;
  @service errors;
  @service store;
  @tracked conditions;

  constructor() {
    super(...arguments);
    this.loadConditionsTask.perform().catch(ignoreCancelation);
  }

  loadConditionsTask = dropTask(async () => {
    let isAmountCondition =
      this.args.conditions[0]?.field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT;
    let isSupplierIdCondition =
      this.args.conditions[0]?.field === APPROVAL_WORKFLOW_CONDITION_FIELDS.SUPPLIER_ID;
    let isUploadedIdCondition =
      this.args.conditions[0]?.field === APPROVAL_WORKFLOW_CONDITION_FIELDS.UPLOADER_ID;

    if (isAmountCondition) {
      let amount = this.args.conditions[0]?.value || { value: null, currency: 'EUR' };

      this.conditions = this.intl.t('approval-workflows.ruleset.conditions.gt', {
        amount: this.intl.formatNumber(amount.value, {
          currency: amount.currency,
          style: 'currency',
        }),
      });
    }

    if (isSupplierIdCondition) {
      let supplierIds = this.args.conditions[0]?.value?.supplier_ids;
      let supplierRecords;
      try {
        supplierRecords = await this.store.findAll('supplier');
      } catch (error) {
        this.errors.handleError(error);
        return;
      }
      let supplierNames = supplierRecords
        .filter(supplier => supplierIds.includes(supplier.get('id')))
        .map(supplier => supplier.get('name'));

      this.conditions = `${this.intl.t('approval-workflows.ruleset.conditions.supplier.eq')} ${supplierNames.join(', ')}`;
    }

    if (isUploadedIdCondition) {
      let uploader_ids = this.args.conditions[0]?.value?.uploader_ids;
      let uploaderRecords;
      try {
        uploaderRecords = await this.store.findAll('membership');
      } catch (error) {
        this.errors.handleError(error);
        return;
      }
      let uploaderNames = uploaderRecords
        .filter(uploader => uploader_ids.includes(uploader.get('id')))
        .map(uploader => uploader.get('fullName'));

      this.conditions = `${this.intl.t('approval-workflows.ruleset.conditions.uploader.eq')} ${uploaderNames.join(', ')}`;
    }
  });
}
