export default {
  "rule-card": "LR",
  "rule-content": "LQ",
  "rule-options": "Le",
  "visible": "LB",
  "dropdown-btn": "LE",
  "delete-btn": "Ld",
  "btn--tertiary": "LI",
  "delete-rule-btn": "Lt",
  "transaction-estimate": "LM"
};
