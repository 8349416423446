/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

export default class BankAccountTileComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service router;

  get iban() {
    let { shouldDisplayStartVerification, account } = this.args;

    if (shouldDisplayStartVerification) {
      return account.ibanObfuscated;
    }

    return account.iban;
  }

  get lastSyncRelative() {
    let { account } = this.args;
    if (!account.lastSyncedAt) return;

    return dayjs(account.lastSyncedAt).fromNow();
  }
}
