export default {
  "wrapper": "ek",
  "title": "eJ",
  "button-icon": "es",
  "download-button": "eF",
  "specification-list": "eg",
  "certified-details-wrapper": "eD",
  "details-wrapper": "eV",
  "certified-specification-list": "eL",
  "specification": "eT",
  "icon": "ez",
  "cross": "ea",
  "checkmark": "eH",
  "pit-link": "eO"
};
