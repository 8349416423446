export default {
  "header-cell": "Jo",
  "border-top": "Jn",
  "header-content": "Ji caption-bold",
  "active": "JA",
  "empty": "JY",
  "card": "Jh",
  "card-holder": "Jf",
  "limit": "JK",
  "align-right": "JG",
  "status": "Jr",
  "hidden": "Jb",
  "no-padding": "Jy"
};
