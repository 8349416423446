export default {
  "container": "Ps",
  "is-sidebar-content": "PF",
  "no-sep": "Pg",
  "details": "PD",
  "detail": "PV",
  "name": "PL",
  "content": "PT",
  "content-text": "Pz",
  "break-word": "Pa",
  "primary": "PH",
  "links-container": "PO",
  "infos-container": "Pm",
  "infos-disclaimer": "Pp",
  "upsell-container": "Px"
};
