export default {
  "row": "mf body-1",
  "active": "mK",
  "cell": "mG body-2",
  "empty": "mr",
  "no-padding": "mb",
  "cell-content": "my",
  "debitor": "mU",
  "avatar": "mj",
  "debitor-details": "mW",
  "debitor-name": "mu",
  "reference": "pS",
  "amount": "pc",
  "row--sidebar-open": "pq",
  "status": "pZ"
};
