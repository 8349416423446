/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CARD_FEES, CARD_LEVELS, CARD_WITHDRAW_FEE } from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';

const CARD_IMAGE_URLS = {
  [CARD_LEVELS.STANDARD]: '/illustrations/cards/illu-card-standard-default.svg',
  [CARD_LEVELS.PLUS]: '/illustrations/cards/illu-card-plus-default.svg',
  [CARD_LEVELS.METAL]: '/illustrations/cards/illu-card-metal-default.svg',
};

const CONCIERGE_PHONE_TEXTS_IMAGE_URLS = {
  de: '/illustrations/concierge/concierge-phone-texts-de.png',
  en: '/illustrations/concierge/concierge-phone-texts-en.png',
  es: '/illustrations/concierge/concierge-phone-texts-es.png',
  fr: '/illustrations/concierge/concierge-phone-texts-fr.png',
  it: '/illustrations/concierge/concierge-phone-texts-it.png',
  pt: '/illustrations/concierge/concierge-phone-texts-en.png', // TODO fix when localized PT asset is available
};

export default class CardComparisonModalDetails extends Component {
  @service intl;
  @service localeManager;
  @service abilities;

  CARD_WITHDRAW_FEE = CARD_WITHDRAW_FEE;

  @tracked locale = this.localeManager.locale;
  @tracked footerVisible = null;

  get isCardFree() {
    return this.isStandardCard && this.args.isOneCardIncluded;
  }

  get fee() {
    return CARD_FEES[this.args.cardLevel];
  }

  get limit() {
    let { cardLevel, cardsMaxLimits } = this.args;
    let { extended_payment_monthly_limit_maximum, atm_monthly_limit_maximum } =
      cardsMaxLimits[cardLevel];

    return { payment: extended_payment_monthly_limit_maximum, atm: atm_monthly_limit_maximum };
  }

  get pricing() {
    return this.args.pricing[this.args.cardLevel].monthly_cost;
  }

  get conditions() {
    let descriptionParams = {
      termsAndConditionsLink: this.intl.t('cards.details.insurance-coverage.conditions.link'),
    };

    switch (this.args.cardLevel) {
      case CARD_LEVELS.STANDARD:
        return {
          title: this.intl.t('cards.details.insurance-coverage.conditions.standard.title'),
          descriptionKey: 'cards.details.insurance-coverage.conditions.standard.description.body',
          description: this.intl.t(
            'cards.details.insurance-coverage.conditions.standard.description.body',
            descriptionParams
          ),
          url: this.intl.t('cards.details.insurance-coverage.conditions.standard.description.url'),
        };
      case CARD_LEVELS.PLUS:
        return {
          title: this.intl.t('cards.details.insurance-coverage.conditions.plus.title'),
          descriptionKey: 'cards.details.insurance-coverage.conditions.plus.description.body',
          description: this.intl.t(
            'cards.details.insurance-coverage.conditions.plus.description.body',
            descriptionParams
          ),
          url: this.intl.t('cards.details.insurance-coverage.conditions.plus.description.url'),
        };
      case CARD_LEVELS.METAL:
        return {
          title: this.intl.t('cards.details.insurance-coverage.conditions.metal.title'),
          descriptionKey: 'cards.details.insurance-coverage.conditions.metal.description.body',
          description: this.intl.t(
            'cards.details.insurance-coverage.conditions.metal.description.body',
            descriptionParams
          ),
          url: this.intl.t('cards.details.insurance-coverage.conditions.metal.description.url'),
        };
    }
  }

  get cardImageUri() {
    return importAsset([CARD_IMAGE_URLS[this.args.cardLevel]]);
  }

  get conciergePhoneTextsImageUri() {
    // cannot build image URI with a template string (because the images won't be added to the built app)
    return importAsset([
      CONCIERGE_PHONE_TEXTS_IMAGE_URLS[this.locale] ?? CONCIERGE_PHONE_TEXTS_IMAGE_URLS.en,
    ]);
  }

  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get isStandardCard() {
    return this.args.cardLevel === CARD_LEVELS.STANDARD;
  }
}
