export default {
  "shadow": "BX",
  "placeholder-line": "BC",
  "placeholder-square": "Bk",
  "loading": "BJ",
  "name": "Bs title-4",
  "iban": "BF caption",
  "transfers-button": "Bg",
  "cards-button": "BD",
  "mandates-button": "BV",
  "menu-item": "BL body-2",
  "close-account": "BT",
  "kyc-disclaimer": "Bz"
};
