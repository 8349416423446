export default {
  "header": "FK",
  "result": "FG",
  "result-value": "Fr",
  "result-value-key": "Fb",
  "result-value-icon": "Fy",
  "green": "FU",
  "red": "Fj",
  "result-value-description": "FW",
  "result-title": "Fu",
  "chevron": "gS",
  "detail": "gc",
  "detail-title": "gq",
  "detail-value": "gZ",
  "tooltip-wrapper": "gR",
  "tooltip": "gQ",
  "disclaimer": "ge"
};
