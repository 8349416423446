export default {
  "infobox": "FS",
  "account-infobox": "Fc FS",
  "check-sidebar-header": "Fq",
  "type": "FZ",
  "validated": "FR",
  "pending": "FQ",
  "received": "Fe",
  "canceled": "FB",
  "declined": "FE",
  "refunded": "Fd",
  "date": "FI",
  "close": "Ft",
  "column-layout": "FM",
  "container": "FP",
  "picto": "Fl",
  "avatar": "FX",
  "status": "FC",
  "general": "Fk",
  "amount": "FJ",
  "counterparty": "Fs"
};
