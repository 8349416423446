export default {
  "row": "pl",
  "icon": "pX",
  "cell": "pC",
  "empty": "pk",
  "no-padding": "pJ",
  "cell-content": "ps",
  "right": "pF",
  "cube": "pg"
};
