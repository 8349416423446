export default {
  "card": "MQ",
  "avatar": "Me",
  "owner": "MB",
  "content": "ME",
  "identity": "Md",
  "fullname": "MI",
  "type": "Mt",
  "menu-container": "MM",
  "dropdown-menu": "MP",
  "menu-item": "Ml body-2",
  "delete": "MX"
};
