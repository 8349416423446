import { template as template_4150d2e741df437a8a25b1d9312424fc } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';
import PowerSelectCustomsMultipleFooter from '@qonto/ui-kit/components/power-select-customs/multiple/footer';
import PowerSelectCustomsMultipleOption from '@qonto/ui-kit/components/power-select-customs/multiple/option';
import PowerSelectCustomsSearch from '@qonto/ui-kit/components/power-select-customs/search';
import PowerSelectCustomsTrigger from '@qonto/ui-kit/components/power-select-customs/trigger';
import { restartableTask, timeout } from 'ember-concurrency';
import { t } from 'ember-intl';
import PowerSelectMultiple from 'ember-power-select/components/power-select-multiple';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
const DEBOUNCE_TIMEOUT = 200;
export default class ApprovalWorkflowForm extends Component {
    constructor(){
        super(...arguments);
        this.optionsTask.perform().catch(ignoreCancelation);
    }
    get mapToOption() {
        if (this.args.mapToOption) return this.args.mapToOption;
        return ({ id, name })=>({
                key: id,
                value: name
            });
    }
    get selected() {
        return (this.optionsTask.last.value?.filter(({ key })=>this.args.selected?.includes(key)) ?? []);
    }
    get selectedValues() {
        return this.selected.map(({ value })=>value).join(', ');
    }
    optionsTask = restartableTask(async (query)=>{
        if (macroCondition(!isTesting())) {
            await timeout(DEBOUNCE_TIMEOUT);
        }
        let result = await this.args.query(query);
        return result.map(this.mapToOption);
    });
    update = (selected)=>{
        // Due to the mapping of query results to options with `mapToOption` the options don't have the same identity after each select.
        // This function can receive two objects with same key when we want to deselect an option.
        // When two objects with the same key are received then they should not be selected.
        let result = selected?.reduce((acc, option)=>{
            let index = acc.findIndex((opt)=>opt.key === option.key);
            if (index >= 0) {
                acc.splice(index, 1);
            } else {
                acc.push(option);
            }
            return acc;
        }, []);
        this.args.update(result);
    };
    static{
        template_4150d2e741df437a8a25b1d9312424fc(`
    <div ...attributes>
      <PowerSelectMultiple
        @triggerClass='power-select-customs-trigger block {{if @error "error"}}'
        @dropdownClass='power-select-custom'
        @afterOptionsComponent={{component PowerSelectCustomsMultipleFooter onChange=@update}}
        @triggerComponent={{component PowerSelectCustomsTrigger selected=this.selectedValues}}
        @beforeOptionsComponent={{component PowerSelectCustomsSearch}}
        @searchMessage={{t 'labels.select_search_message'}}
        @noMatchesMessage={{t 'labels.select_no_matches_message'}}
        @loadingMessage={{t 'labels.select_loading_message'}}
        @searchPlaceholder={{t 'labels.search'}}
        @renderInPlace={{@renderInPlace}}
        @placeholder={{@placeholder}}
        @selected={{this.selected}}
        @options={{this.optionsTask.last}}
        @onClose={{@onClose}}
        @closeOnSelect={{false}}
        @allowClear={{true}}
        @searchEnabled={{true}}
        @search={{this.optionsTask.perform}}
        @onChange={{this.update}}
        as |option select|
      >
        <PowerSelectCustomsMultipleOption @select={{select}} @key={{option.key}}>
          {{option.value}}
        </PowerSelectCustomsMultipleOption>
      </PowerSelectMultiple>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
