export default {
  "row": "JU",
  "active": "Jj",
  "animated": "JW",
  "fadein-item": "Ju",
  "cell": "sS body-2",
  "cell-content": "sc",
  "no-padding": "sq",
  "hidden": "sZ",
  "disabled": "sR",
  "not-disabled": "sQ",
  "card-icon": "se",
  "card-infos": "sB",
  "card-digits": "sE",
  "card-digits-warning": "sd",
  "status-puce": "sI",
  "live": "st",
  "waiting-funds": "sM",
  "ghost": "sP",
  "payment-limit-bar": "sl",
  "align-right": "sX"
};
