export default {
  "budget-edit-form": "IM",
  "period-label": "IP",
  "periods": "Il",
  "yearly": "IX",
  "tooltips": "IC",
  "year": "Ik",
  "total-budget": "IJ",
  "total-budget-value": "Is",
  "divider": "IF",
  "buttons": "Ig"
};
