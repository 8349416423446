export default {
  "chip": "Du",
  "chip--hidden": "VS",
  "chip--collapsed": "Vc",
  "chip--selected": "Vq",
  "chip--disabled": "VZ",
  "wrapper": "VR",
  "content": "VQ body-1",
  "clear": "Ve",
  "clear-icon": "VB"
};
