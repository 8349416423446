export default {
  "attachments-suggested-sidebar": "Rh",
  "aside-section": "Rf",
  "search-section": "RK",
  "scrolled-top": "RG",
  "aside-title": "Rr caption-bold",
  "search-input": "Rb",
  "toolbar-row": "Ry",
  "invoices-list-section": "RU",
  "attachments-suggested-sidebar-footer": "Rj",
  "pagination-wrapper": "RW",
  "pagination-loading": "Ru",
  "cta-wrapper": "QS",
  "cancel-button": "Qc",
  "content": "Qq",
  "illustration": "QZ mb-32",
  "title": "QR mb-8 title-4",
  "subtitle": "QQ body-2"
};
