export default {
  "modal": "Tm",
  "features": "Tp",
  "close-button": "Tx",
  "header": "Tw title-2",
  "header-text": "TN body-2",
  "feature": "Tv",
  "subheader": "To title-4",
  "text": "Tn body-2",
  "cta": "Ti",
  "container-img": "TA",
  "img": "TY",
  "link": "Th"
};
