export default {
  "container": "lj",
  "is-loaded": "lW",
  "disabled": "lu",
  "image": "XS",
  "infos": "Xc",
  "fully-masked": "Xq",
  "black": "XZ",
  "infos-type--virtual": "XR",
  "infos-holder": "XQ",
  "infos-pan": "Xe",
  "infos-pan-numbers": "XB",
  "infos-exp-date": "XE",
  "infos-cvv": "Xd",
  "infos-caption": "XI",
  "copy-btn": "Xt",
  "description-details": "XM"
};
