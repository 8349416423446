/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_STATUSES, CARD_TYPES } from 'qonto/constants/cards';

export default class CardFiltersComponent extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;
  @service segment;

  cardTypeOptions = Object.values(this.cardTypeFilters);

  get cardStatusFilters() {
    let { PENDING, LIVE, PAUSED, STOLEN, LOST, PIN_BLOCKED, DELETED, PRE_EXPIRED, EXPIRED } =
      CARD_STATUSES;
    if (this.args.activeTab === 'archived') {
      return [
        { label: this.intl.t('cards.status.expired'), value: [EXPIRED, PRE_EXPIRED] },
        { label: this.intl.t('cards.status.deleted'), value: [DELETED] },
        { label: this.intl.t('cards.status.lost'), value: [LOST] },
        { label: this.intl.t('cards.status.stolen'), value: [STOLEN] },
      ];
    } else {
      return [
        { label: this.intl.t('cards.status.live'), value: [LIVE] },
        { label: this.intl.t('cards.status.kyc.pending'), value: [PENDING] },
        { label: this.intl.t('cards.status.paused'), value: [PAUSED, PIN_BLOCKED] },
      ];
    }
  }

  get cardTypeFilters() {
    return [
      {
        type: 'physical',
        label: this.intl.t('cards.list.filters.physical-cards'),
        value: CARD_TYPES.PHYSICALS,
      },
      {
        type: 'virtual',
        label: this.intl.t('cards.list.filters.virtual-cards'),
        value: CARD_TYPES.VIRTUALS,
      },
    ];
  }

  get cardTypeValue() {
    let cardLevels = this.args.filters?.card_levels;
    if (!cardLevels?.length) {
      return null;
    }

    if (cardLevels.every(level => CARD_TYPES.PHYSICALS.includes(level))) {
      return this.cardTypeOptions.find(option => option.type === 'physical');
    }
    return this.cardTypeOptions.find(option => option.type === 'virtual');
  }

  get hasTeamsFilter() {
    return this.args.hasTeamsFilter && this.abilities.can('filter team');
  }

  get teamValue() {
    let teamIds = this.args.filters?.team_ids;
    return teamIds && this.teams.find(team => team.id === teamIds[0]);
  }

  get teams() {
    return this.organizationManager.organization.teams;
  }

  get cardStatusValue() {
    let cardStatus = this.args.filters?.statuses;
    return (
      cardStatus?.length && this.cardStatusFilters.find(status => status.value[0] === cardStatus[0])
    );
  }

  @action
  handleTeamSelect(selectedTeam) {
    if (selectedTeam) {
      this.args.triggerFilter('team_ids', [selectedTeam.id]);
      this.segment.track('cards_filter_team');
    } else {
      this.args.triggerFilter('team_ids', []);
    }
  }

  @action
  handleSelectCardType(cardTypeOption) {
    this.args.triggerFilter('card_levels', cardTypeOption?.value || []);
    if (cardTypeOption?.type === 'physical') {
      this.segment.track('cards_filter_physical');
    } else {
      this.segment.track('cards_filter_virtual');
    }
  }

  @action
  handleCardStatusSelect(selectedStatus) {
    if (selectedStatus) {
      this.args.triggerFilter('statuses', selectedStatus.value);
      this.segment.track('cards_filter_status');
    } else {
      this.args.triggerFilter('statuses', []);
    }
  }
}
