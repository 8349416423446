/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { SORT_ORDER } from 'qonto/constants/sort';

const DEFAULT_SORT_FIELD = 'emitted_at';
const DEFAULT_SORT_ORDER = 'desc';

export default class BudgetDetailsTableHeaderComponent extends Component {
  get sortField() {
    let { sortBy } = this.args;

    return sortBy?.split(':')[0] || DEFAULT_SORT_FIELD;
  }

  get sortOrder() {
    let { sortBy } = this.args;

    return sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  }

  @action
  handleSortBy(sortField) {
    let { handleSortBy } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (sortField === this.sortField) {
      sortOrder = this.sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    return handleSortBy?.(`${sortField}:${sortOrder}`);
  }
}
