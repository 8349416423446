export default {
  "card-sidebar-header": "Mu",
  "content": "PS",
  "card-image-container": "Pc",
  "card-image": "Pq",
  "legacy": "PZ",
  "type": "PR",
  "type-container": "PQ",
  "type-close": "Pe",
  "type-status-awaiting": "PB",
  "type-status-live": "PE",
  "type-status-paused": "Pd",
  "type-status-ghost": "PI",
  "actions-panel": "Pt",
  "actions-panel-header": "PM",
  "action-btn": "PP",
  "disclaimer": "Pl",
  "infobox": "PX",
  "btn-panel": "PC",
  "required-action-btn": "Pk",
  "kyc-disclaimer": "PJ"
};
