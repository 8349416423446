export default {
  "wrapper": "Xw",
  "content-wrapper": "XN",
  "no-fee": "Xv",
  "concierge": "Xo",
  "no-fee-item": "Xn",
  "concierge-item": "Xi",
  "no-fee-icon": "XA",
  "lounge-icon": "XY",
  "insurance-conditions": "Xh",
  "description": "Xf",
  "footer-detail": "XK",
  "title": "XG",
  "footer-price": "Xr",
  "footer-content": "Xb",
  "lounge-text": "Xy",
  "lounge": "XU",
  "lounge-images": "Xj",
  "img": "XW",
  "footer-sticky-bar": "Xu",
  "visible": "CS",
  "content": "Cc",
  "content-card": "Cq"
};
