export default {
  "row": "zA",
  "active": "zY",
  "cell": "zh",
  "empty-cell": "zf",
  "counterparty": "zK",
  "counterparty-info": "zG",
  "name": "zr body-1",
  "qonto": "zb",
  "currency": "zy",
  "account-number": "zU caption",
  "custom-labels": "zj",
  "label": "zW"
};
