export default {
  "row": "kH",
  "active": "kO",
  "status": "km",
  "align-right": "kp",
  "animated": "kx",
  "fadein-item": "kw",
  "cell": "kN body-2",
  "cell-content": "kv",
  "amount": "ko body-1",
  "subtitle": "kn caption",
  "no-padding": "ki",
  "note": "kA"
};
