export default {
  "header-cell": "Cf",
  "header-content": "CK caption-bold",
  "active": "CG",
  "hidden": "Cr",
  "empty": "Cb",
  "align-right": "Cy",
  "no-padding": "CU",
  "request-type": "Cj",
  "amount": "CW",
  "approval": "Cu"
};
