export default {
  "row": "OL",
  "cell": "OT body-2",
  "empty": "Oz",
  "cell-content": "Oa",
  "cell-content--amount": "OH body-1",
  "icon": "OO",
  "description": "Om",
  "tertiary": "Op",
  "details": "Ox"
};
