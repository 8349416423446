export default {
  "container": "ls",
  "container-metal": "lF",
  "pickers": "lg",
  "picker-wrapper": "lD",
  "picker": "lV",
  "checked": "lL",
  "lilac": "lT",
  "black": "lz",
  "mineral-gray": "la",
  "sand-gold": "lH",
  "graphite-black": "lO"
};
