export default {
  "container": "sN",
  "content": "sv",
  "subtitle": "so",
  "switch": "sn",
  "card-details": "si",
  "asset-container": "sA",
  "asset-container-padded": "sY",
  "asset": "sh",
  "right-container": "sf",
  "card-brand-title": "sK",
  "card-brand-badge": "sG",
  "free-subtitle": "sr",
  "benefit-container": "sb",
  "checkmark": "sy",
  "container-metal": "sU"
};
