export default {
  "attachment-viewer": "RR",
  "sidebar": "RQ",
  "attachment-viewer-details": "Re",
  "attachment-viewer-filename": "RB body-2",
  "attachment-viewer-actions": "RE",
  "dropzone": "Rd",
  "dropzone-visible": "RI",
  "attachment-main": "Rt"
};
