/* import __COLOCATED_TEMPLATE__ from './payment-collection.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class DirectDebitCollectionsSubscriptionPaymentCollectionComponent extends Component {
  @action
  onChangePaymentOption(useExistingMandate) {
    this.args.onSelectMandate(useExistingMandate ? this.args.mandates[0] : null);
  }
}
